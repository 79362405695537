import { Caption } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getItemModelBidCount,
    getItemModelIsLocked,
    getItemModelIsPassed,
    getItemModelIsSold,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { getLiveItemStatusOpen } from '@/redux/modules/liveItemStatus';
import { isUserLoggedIn } from '@/redux/modules/account/user/user.selectors';
import { useAppSelector } from '@/redux/hooks';
import ItemCardMenu from '@/components/ItemCard/components/Menu/ItemCardMenu';
import ItemCardPriceRow from '@/components/GridItemCard/Card/components/ItemCardPriceRow/ItemCardPriceRow';
import styled, { css } from 'styled-components';

export type CardPriceProps = {
    enterLinkHoverState: () => void;
    isCardHovered: boolean;
    isLinkHovered: boolean;
    itemId: number;
    leaveLinkHoverState: () => void;
    onNavigate?: () => void;
};

const CardPrice = ({
    enterLinkHoverState,
    isCardHovered,
    isLinkHovered,
    itemId,
    leaveLinkHoverState,
    onNavigate,
}: CardPriceProps) => {
    const isLoggedIn = useAppSelector(isUserLoggedIn);
    const bidCount = useAppSelector((state) => getItemModelBidCount(state, itemId));
    const isLotOpen = useAppSelector((state) => getLiveItemStatusOpen(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isLocked = useAppSelector((state) => getItemModelIsLocked(state, itemId));

    const isPending = isLocked && !isPassed && !isSold && !isLotOpen;

    return (
        <CardPriceSection>
            <StyledBidInfoSection>
                <StyledBidInfo>
                    <ItemCardPriceRow
                        isHovered={isLinkHovered}
                        itemId={itemId}
                        onMouseEnter={enterLinkHoverState}
                        onMouseLeave={leaveLinkHoverState}
                        onNavigate={() => onNavigate?.()}
                    />
                    {!isSold && !isPassed && !isPending && bidCount > 0 && (
                        <StyledBidCount
                            $isHovered={isLinkHovered}
                            onMouseEnter={enterLinkHoverState}
                            onMouseLeave={leaveLinkHoverState}
                        >
                            <FormattedMessage
                                id="itemBidding.bid"
                                values={{ bidCount }}
                            />
                        </StyledBidCount>
                    )}
                </StyledBidInfo>
                {isLoggedIn && (
                    <ItemCardMenu
                        itemId={itemId}
                        showIcon={isCardHovered}
                    />
                )}
            </StyledBidInfoSection>
        </CardPriceSection>
    );
};

export default CardPrice;

const CardPriceSection = styled.section`
    grid-area: currentbidprice;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const StyledBidInfoSection = styled.section`
    display: flex;
    justify-content: space-between;
`;

const StyledBidInfo = styled.section`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 4px;
`;

const StyledBidCount = styled(Caption)<{ $isHovered: boolean }>`
    ${({ $isHovered }) =>
        $isHovered &&
        css`
            color: ${({ theme }) => theme.colors.blue200};
            text-decoration: underline;
        `}
`;
