import { BodyPrimary, BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import { getItemModelSellerId, getItemModelSellerName } from '@/redux/modules/item/summary/itemSummary.selectors';
import { getSellerUrl } from '@/utils/urls';
import { SubtleMonochromeLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import styled, { css } from 'styled-components';

export type ItemCardAuctionNameProps = {
    itemId: number;
    onNavigate?: () => void;
    showFullAuctionName?: boolean;
    usePrimary?: boolean;
};

const ItemCardAuctionName = ({
    itemId,
    onNavigate,
    showFullAuctionName,
    usePrimary = false,
}: ItemCardAuctionNameProps) => {
    const sellerId = useAppSelector((state) => getItemModelSellerId(state, itemId));
    const sellerName = useAppSelector((state) => getItemModelSellerName(state, itemId));

    const TextComponent = usePrimary ? BodyPrimary : BodySecondary;

    return (
        <StyledTruncateWrapper>
            {sellerName ? (
                <TextComponent>
                    <AuctioneerLink
                        $showFullAuctionName={showFullAuctionName}
                        href={getSellerUrl(sellerId, sellerName)}
                        onClick={() => onNavigate?.()}
                    >
                        {sellerName}
                    </AuctioneerLink>
                </TextComponent>
            ) : (
                <AuctioneerLinkSpan>
                    <FormattedMessage id="no_auctioneer_name" />
                </AuctioneerLinkSpan>
            )}
        </StyledTruncateWrapper>
    );
};

export default ItemCardAuctionName;

const StyledTruncateWrapper = styled.span`
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-grow: 1;
    text-align: right;
`;

const AuctioneerLink = styled(SubtleMonochromeLink)<{ $showFullAuctionName?: boolean }>`
    color: ${({ theme }) => theme.colors.grey100};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    ${({ $showFullAuctionName }) =>
        Boolean($showFullAuctionName) &&
        css`
            max-width: unset;
        `}

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        max-width: 82px;
    }
`;

const AuctioneerLinkSpan = styled.span`
    display: flex;
    color: ${({ theme }) => theme.colors.grey100};
`;
