import { BodySecondary } from '@liveauctioneers/hammer-ui-core/text';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getIsTimedPlusItemBiddingClosed,
    getItemModelCatalogId,
    getItemModelCatalogStatus,
    getItemModelCurrency,
    getItemModelIsPassed,
    getItemModelIsPaused,
    getItemModelIsSold,
    getItemModelSellerId,
    getItemModelStartPrice,
    getItemModelStartTime,
    getItemModelTitle,
    getItemReserveMet,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { isSearchPage } from '@/utils/urls';
import { ItemCardComponent, ItemCardInfo } from '../../../types/types';
import {
    ItemCardNavigationTrigger,
    useTrackItemCardNavigationAnalytics,
    useTrackRegisterForAuctionPressedAnalytics,
} from '@/utils/analytics';
import { ItemListNames, trackProductClicked } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useIsOnCategorySearchOrPriceGuidePage } from '@/hooks/useIsOnCategorySearchOrPriceGuidePage';
import { useLocation } from 'react-router-dom';
import { useTrackItemCardClicked } from '@/hooks/kameleoon/tracking/useTrackItemCardClicked';
import ImageRow from '@/components/ItemCard/ImageRow';
import ItemCardAuctionName from '@/components/GridItemCard/Card/components/ItemCardAuctionName/ItemCardAuctionName';
import ItemCardCountdown from '@/components/GridItemCard/Card/components/ItemCardCountdown/ItemCardCountdown';
import ItemCardSaveIcon from '@/components/GridItemCard/Card/components/ItemCardSaveIcon/ItemCardSaveIcon';
import ItemCardTitle from '@/components/GridItemCard/Card/components/ItemCardTitle/ItemCardTitle';
import React, { useCallback, useState } from 'react';
import RegisterLinkOrPrice from './components/RegisterLinkOrPrice/RegisterLinkOrPrice';
import styled from 'styled-components';
import theme from '@liveauctioneers/caterwaul-components/lib/theme/theme';
import TimedBadgeContainer from './components/TimedBadgeContainer/TimedBadgeContainer';
import useIsWiderThan from '@/hooks/useIsWiderThan';

const TimedPlusItemCard: ItemCardComponent = ({ className, dimension, itemId }) => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const isCatalogPage = pathname.includes('/catalog');

    const [linkHover, setLinkHover] = useState(false);

    const saleStartTs = useAppSelector((state) => getItemModelStartTime(state, itemId));
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));
    const isPassed = useAppSelector((state) => getItemModelIsPassed(state, itemId));
    const isPaused = useAppSelector((state) => getItemModelIsPaused(state, itemId));
    const isTimedPlusBiddingClosed = useAppSelector((state) => getIsTimedPlusItemBiddingClosed(state, itemId));
    const isReserveMet = useAppSelector((state) => getItemReserveMet(state, itemId));
    const catalogId = useAppSelector((state) => getItemModelCatalogId(state, itemId));
    const currency = useAppSelector((state) => getItemModelCurrency(state, itemId));
    const houseId = useAppSelector((state) => getItemModelSellerId(state, itemId));
    const price = useAppSelector((state) => getItemModelStartPrice(state, itemId));
    const catalogStatus = useAppSelector((state) => getItemModelCatalogStatus(state, itemId));
    const title = useAppSelector((state) => getItemModelTitle(state, itemId));

    const trackItemCardClicked = useTrackItemCardClicked();
    const trackItemCardNavigationAnalytics = useTrackItemCardNavigationAnalytics();
    const trackRegisterForAuctionPressedAnalytics = useTrackRegisterForAuctionPressedAnalytics();

    const showStatusBanner = !pathname.includes('/c/') && (!isSold || isReserveMet) && !isPaused;

    const isMobile = !useIsWiderThan(theme.breakpoints.tabletNarrowWidthNum);
    const displayItemName = isMobile && (isSold || isPassed || isPaused || saleStartTs > Math.round(Date.now() / 1000));

    const showNewFavoriteButton = useIsOnCategorySearchOrPriceGuidePage();

    const onCardClick = useCallback(() => {
        const itemListName = isSearchPage(pathname) ? ItemListNames.SearchPage : ItemListNames.CategoryPage;

        if (!isSold) {
            trackItemCardClicked();
            dispatch(
                trackProductClicked({
                    catalogId,
                    catalogStatus,
                    currency,
                    houseId,
                    itemId,
                    itemListName,
                    price,
                    title,
                })
            );
        }
    }, [
        catalogId,
        catalogStatus,
        currency,
        dispatch,
        houseId,
        isSold,
        itemId,
        pathname,
        price,
        title,
        trackItemCardClicked,
    ]);

    const enterLinkHoverState = React.useCallback(() => {
        setLinkHover(true);
    }, []);

    const leaveLinkHoverState = React.useCallback(() => {
        setLinkHover(false);
    }, []);

    const onNavigateInitiated = (trigger: ItemCardNavigationTrigger) => {
        trackItemCardNavigationAnalytics({ houseId, itemId, trigger });
    };

    return (
        <StyledArticle
            $dimension={dimension}
            className={className}
            data-testid={`timed-plus-item-card-${itemId}`}
            onClick={onCardClick}
        >
            <TimedPlusItemCardContainer $dimension={dimension}>
                <PlacedItemCardSaveIcon>
                    <ItemCardSaveIcon
                        itemId={itemId}
                        labeled={!isMobile && showNewFavoriteButton}
                    />
                </PlacedItemCardSaveIcon>
                <TimedBadgeContainer itemId={itemId} />
                <PlacedImageContainer
                    onMouseEnter={enterLinkHoverState}
                    onMouseLeave={leaveLinkHoverState}
                >
                    <ImageRow
                        itemId={itemId}
                        onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.ImageClick)}
                        showBidStatus={showStatusBanner}
                        showImageWithHover={!isMobile}
                    />
                </PlacedImageContainer>

                <PlacedCatalogInformation data-testid="timed-plus-item-card.catalog-information">
                    {isTimedPlusBiddingClosed ? (
                        <BodySecondary>
                            <FormattedMessage id="lot_closed" />
                        </BodySecondary>
                    ) : (
                        <StyledCatalogTimeInformation data-testid="timed-plus-item-card.catalog-information.date-information">
                            <ItemCardCountdown
                                itemId={itemId}
                                onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.CountdownClick)}
                            />
                            {!isCatalogPage && !isMobile && (
                                <ItemCardAuctionName
                                    itemId={itemId}
                                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.HouseNameClick)}
                                />
                            )}
                            {!isCatalogPage && isMobile && displayItemName && (
                                <ItemCardAuctionName
                                    itemId={itemId}
                                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.HouseNameClick)}
                                />
                            )}
                        </StyledCatalogTimeInformation>
                    )}
                </PlacedCatalogInformation>
                <PlacedItemCardTitle
                    isHovered={linkHover}
                    itemId={itemId}
                    onMouseEnter={enterLinkHoverState}
                    onMouseLeave={leaveLinkHoverState}
                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.TitleClick)}
                    showLotNumber
                />
                <PlacedRegisterLinkOrPrice
                    itemId={itemId}
                    linkHover={linkHover}
                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.PriceClick)}
                    onRegisterForAuctionClicked={() => trackRegisterForAuctionPressedAnalytics()}
                />
            </TimedPlusItemCardContainer>
        </StyledArticle>
    );
};

const info: ItemCardInfo = {
    dimensions: { desktop: 184, mobile: 164 },
    ItemCardComponent: TimedPlusItemCard,
};

export default info;

const PlacedImageContainer = styled.div`
    grid-area: img;
    position: relative;
    aspect-ratio: 1 / 1;
`;

const PlacedItemCardTitle = styled(ItemCardTitle)`
    grid-area: title;
`;

const PlacedRegisterLinkOrPrice = styled(RegisterLinkOrPrice)`
    grid-area: registerlinkorprice;
    height: 100%;
`;

const PlacedCatalogInformation = styled.div`
    grid-area: time;
    display: flex;
    justify-content: space-between;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledCatalogTimeInformation = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const PlacedItemCardSaveIcon = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 2;
`;

const TimedPlusItemCardContainer = styled.section<{ $dimension: number }>`
    position: relative;
    width: inherit;
    min-width: 0;
    display: grid;
    grid-template-columns: ${({ $dimension }) => ($dimension ? `${$dimension}px` : '1fr')};
    grid-template-rows: min-content 22px min-content 4px min-content 4px min-content 24px;
    grid-template-areas:
        'img'
        '.'
        'time'
        '.'
        'title'
        '.'
        'registerlinkorprice'
        '.'
        'easyshipping'
        '.';
    justify-content: center;
`;

const StyledArticle = styled.article<{ $dimension }>`
    width: ${({ $dimension }) => ($dimension ? `${$dimension}px` : 'auto')};
`;
