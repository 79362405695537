import { AppDispatch, AppGetState } from '@/redux/store';
import { sleep } from '@/utils/sleep';

/**
 * Wait for specified time before dispatching action
 * @param actionCreator The action that will be called after the timeout
 * @param dispatch Should be the dispatch function from either the `useAppDispatch` hook, or from an action/thunk creator's params
 * @param actionArgs The arguments that will be passed into the actionCreator
 * @param [waitTimeBeforeCall=1500] The wait time before the dispatch is called.
 */
export async function waitToDispatch<ActionArgs>(
    actionCreator: (args: ActionArgs) => (dispatch: AppDispatch, getState: AppGetState) => Promise<any>,
    dispatch: AppDispatch,
    actionArgs?: ActionArgs,
    waitTimeBeforeCall = 1500
) {
    await sleep(waitTimeBeforeCall);
    await dispatch(actionCreator(actionArgs));
}
