import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import {
    getItemIndex,
    getItemModelCatalogId,
    getItemModelCatalogStatus,
    getItemModelCurrency,
    getItemModelIsSold,
    getItemModelPromoted,
    getItemModelSellerId,
    getItemModelStartPrice,
    getItemModelTitle,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { isSearchPage } from '@/utils/urls';
import { ItemCardComponent, ItemCardInfo } from '@/components/GridItemCard/types/types';
import { ItemCardNavigationTrigger, useTrackItemCardNavigationAnalytics } from '@/utils/analytics';
import { ItemListNames, trackProductClicked, TrackProductClickedProperties } from '@/redux/modules/analytics';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { useLocation } from 'react-router';
import { useTrackItemCardClicked } from '@/hooks/kameleoon/tracking/useTrackItemCardClicked';
import CardImage from './components/CardImage/CardImage';
import CardInfo from './components/CardInfo/CardInfo';
import CardPrice from './components/CardPrice/CardPrice';
import InfoBadge from '@liveauctioneers/caterwaul-components/lib/Badges/InfoBadge';
import ItemCardTitle from '@/components/GridItemCard/Card/components/ItemCardTitle/ItemCardTitle';
import noop from '@/utils/noop';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '@liveauctioneers/caterwaul-components/lib/theme/theme';
import useIsWiderThan from '@/hooks/useIsWiderThan';
import usePrevious from '@liveauctioneers/caterwaul-components/lib/hooks/usePrevious';
import useVisibilitySensor from '@rooks/use-visibility-sensor';

const CategorySearchCard: ItemCardComponent = ({
    className,
    dimension,
    itemId,
    lazyLoadImage,
    pagePosition,
    trackClick = noop,
    trackVisible = noop,
}) => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const index = useAppSelector((state) => getItemIndex(state, itemId));
    const promoted = useAppSelector((state) => getItemModelPromoted(state, itemId));
    const catalogId = useAppSelector((state) => getItemModelCatalogId(state, itemId));
    const currency = useAppSelector((state) => getItemModelCurrency(state, itemId));
    const houseId = useAppSelector((state) => getItemModelSellerId(state, itemId));
    const price = useAppSelector((state) => getItemModelStartPrice(state, itemId));
    const catalogStatus = useAppSelector((state) => getItemModelCatalogStatus(state, itemId));
    const title = useAppSelector((state) => getItemModelTitle(state, itemId));
    const isSold = useAppSelector((state) => getItemModelIsSold(state, itemId));

    const trackCardClicked = useTrackItemCardClicked();
    const trackItemCardNavigationAnalytics = useTrackItemCardNavigationAnalytics();
    // we track the position in search results, to know if user clicks 1st item, 5th, etc
    const algoliaTrackingIndex = pagePosition || index;

    const [cardHover, setCardHover] = useState(false);
    const [linkHover, setLinkHover] = useState(false);
    const isMobile = !useIsWiderThan(theme.breakpoints.tabletNarrowWidthNum);
    const cardRef = useRef(null);
    const [hasVisibilityBeenTracked, setHasVisibilityBeenTracked] = useState(false);
    const { isVisible } = useVisibilitySensor(cardRef, {
        intervalCheck: false,
        resizeCheck: true,
        scrollCheck: true,
    });

    const previousIsVisible = usePrevious(isVisible);

    useEffect(() => {
        const isNewlyVisible = !previousIsVisible && isVisible;
        if (isNewlyVisible && !hasVisibilityBeenTracked) {
            trackVisible?.(itemId, promoted);
            setHasVisibilityBeenTracked(true);
        }
    }, [isVisible, itemId, previousIsVisible, promoted, trackVisible, hasVisibilityBeenTracked]);

    const enterHoverState = React.useCallback(() => {
        setCardHover(true);
    }, []);

    const leaveHoverState = React.useCallback(() => {
        setCardHover(false);
    }, []);

    const enterLinkHoverState = React.useCallback(() => {
        setLinkHover(true);
    }, []);

    const leaveLinkHoverState = React.useCallback(() => {
        setLinkHover(false);
    }, []);

    const onCardClick = useCallback(() => {
        const itemListName = isSearchPage(pathname) ? ItemListNames.SearchPage : ItemListNames.CategoryPage;

        if (!isSold) {
            const itemInformation: TrackProductClickedProperties = {
                catalogId,
                catalogStatus,
                currency,
                houseId,
                itemId,
                itemListName,
                price,
                title,
            };
            trackCardClicked();
            dispatch(trackProductClicked(itemInformation));
        }
        trackClick(itemId, algoliaTrackingIndex);
    }, [
        pathname,
        isSold,
        trackClick,
        itemId,
        algoliaTrackingIndex,
        catalogId,
        catalogStatus,
        currency,
        houseId,
        price,
        title,
        trackCardClicked,
        dispatch,
    ]);

    const onNavigateInitiated = (trigger: ItemCardNavigationTrigger) => {
        trackItemCardNavigationAnalytics({ houseId, itemId, trigger });
    };

    return (
        <Card
            $dimension={dimension}
            className={className}
            data-testid={`category-search-card-${itemId}`}
            onClick={onCardClick}
            onMouseEnter={enterHoverState}
            onMouseLeave={leaveHoverState}
            ref={cardRef}
        >
            <CategorySearchCardGrid $dimension={dimension}>
                {promoted && (
                    <PlacedFeatureBadge color="lightGrey">
                        <FormattedMessage id="featured" />
                    </PlacedFeatureBadge>
                )}
                <CardImage
                    canShowEasyShipping
                    enterLinkHoverState={enterLinkHoverState}
                    isMobile={isMobile}
                    itemId={itemId}
                    lazyLoadImage={lazyLoadImage}
                    leaveLinkHoverState={leaveLinkHoverState}
                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.ImageClick)}
                />
                <CardInfo
                    itemId={itemId}
                    onNavigate={onNavigateInitiated}
                />
                <CardTitle
                    isHovered={linkHover}
                    itemId={itemId}
                    onMouseEnter={enterLinkHoverState}
                    onMouseLeave={leaveLinkHoverState}
                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.TitleClick)}
                />
                <CardPrice
                    enterLinkHoverState={enterLinkHoverState}
                    isCardHovered={cardHover}
                    isLinkHovered={linkHover}
                    itemId={itemId}
                    leaveLinkHoverState={leaveLinkHoverState}
                    onNavigate={() => onNavigateInitiated(ItemCardNavigationTrigger.PriceClick)}
                />
            </CategorySearchCardGrid>
        </Card>
    );
};

const info: ItemCardInfo = {
    ItemCardComponent: CategorySearchCard,
};

export default info;

const Card = styled.article<{ $dimension }>`
    width: ${({ $dimension }) => ($dimension ? `${$dimension}px` : 'auto')};
`;

const CategorySearchCardGrid = styled.section<{ $dimension: number }>`
    position: relative;
    width: inherit;
    min-width: 0;
    display: grid;
    grid-template-columns: ${({ $dimension }) => ($dimension ? `${$dimension}px` : '1fr')};
    grid-template-rows: min-content 22px min-content 4px min-content min-content min-content 8px min-content;
    grid-template-areas:
        'itemimage'
        '.'
        'iteminfo'
        '.'
        'title'
        '.'
        'currentbidprice'
        '.'
        'easyshipping';
    justify-content: center;
`;

const PlacedFeatureBadge = styled(InfoBadge)`
    position: absolute;
    left: 8px;
    top: 8px;
`;

const CardTitle = styled(ItemCardTitle)`
    grid-area: title;
`;
