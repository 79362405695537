import * as React from 'react';
import { getIsTimedPlus } from '@/redux/modules/item/summary/itemSummary.selectors';
import { ItemCardComponent, ItemCardInfo } from '@/components/GridItemCard/types/types';
import { TimedPlusItemCard } from '../index';
import { useAppSelector } from '@/redux/hooks';
import CategorySearchCard from '@/components/GridItemCard/Card/CardOptions/CategorySearchCards/CategorySearchCard';

const TimedOrCategorySearchCard: ItemCardComponent = (props) => {
    const isTimedPlus = useAppSelector((state) => getIsTimedPlus(state, props.itemId));

    const Component = isTimedPlus ? TimedPlusItemCard.ItemCardComponent : CategorySearchCard.ItemCardComponent;

    return <Component {...props} />;
};

const info: ItemCardInfo = { ItemCardComponent: TimedOrCategorySearchCard };

export const CarouselTimedOrCategorySearchCard: ItemCardInfo = {
    dimensions: { desktop: 184, mobile: 184 },
    ItemCardComponent: TimedOrCategorySearchCard,
};

export default info;
