import { BodyPrimary } from '@liveauctioneers/hammer-ui-core/text';
import {
    getItemModelLotNumber,
    getItemModelSEOExcluded,
    getItemModelTitle,
} from '@/redux/modules/item/summary/itemSummary.selectors';
import { SubtleMonochromeLink } from '@liveauctioneers/hammer-ui-core/link';
import { useAppSelector } from '@/redux/hooks';
import { useItemOrPriceResultLink } from '@/hooks/useItemOrPriceResultLink';
import noop from '@/utils/noop';
import styled, { css } from 'styled-components';

type ItemCardTextProps = {
    $myBidsCardHovered?: boolean;
    className?: string;
    isHovered?: boolean;
    itemId: number;
    lineCount?: number;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onNavigate?: (itemUrl?: string) => void;
    showLotNumber?: boolean;
    testid?: string;
};

export default function ItemCardTitle({
    className,
    isHovered = false,
    itemId,
    lineCount = 2,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onNavigate,
    showLotNumber = false,
    testid,
}: ItemCardTextProps) {
    const title = useAppSelector((state) => getItemModelTitle(state, itemId));
    const lotNumber = useAppSelector((state) => getItemModelLotNumber(state, itemId));
    const isSEOExcluded = useAppSelector((state) => getItemModelSEOExcluded(state, itemId));
    const itemUrl = useItemOrPriceResultLink(itemId, true);

    return (
        <ContainerLink
            $isHovered={isHovered}
            $lineCount={lineCount}
            className={className}
            href={itemUrl}
            onClick={() => onNavigate?.(itemUrl)}
            rel={isSEOExcluded ? 'nofollow' : ''}
        >
            <BodyPrimary
                data-testid={testid}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {showLotNumber ? `${lotNumber}: ${title}` : title}
            </BodyPrimary>
        </ContainerLink>
    );
}

export const hoverStyles = css`
    color: ${({ theme }) => theme.colors.blue200};
    text-decoration: underline;
`;

const ContainerLink = styled(SubtleMonochromeLink)<{
    $isHovered?: boolean;
    $lineCount: number;
}>`
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
    min-width: 0;

    /* I think this works on all browsers */
    span {
        -webkit-line-clamp: ${({ $lineCount }) => $lineCount};
        overflow: hidden;
        text-overflow: ellipsis;

        /* stylelint-disable value-no-vendor-prefix  */
        /* stylelint-disable property-no-vendor-prefix */
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    &:hover span {
        ${hoverStyles}
    }

    ${({ $isHovered }) =>
        $isHovered &&
        css`
            span {
                color: ${({ theme }) => theme.colors.blue200};
                text-decoration: underline;
            }
        `}
`;
