import { ItemCardInfo } from '../types/types';
import { mergedCaterwaulAndHammerUIThemes as theme } from '@/theme/mergedCaterwaulAndHammerUIThemes';
import styled from 'styled-components';

export type GridBreakpoints = {
    breakpoints?: {
        [key: number]: {
            column: number;
            gap?: { column?: number; row?: number };
        };
    };
    column: number;
    gap?: { column?: number; row?: number };
};

type Props = {
    breakpoints?: GridBreakpoints;
    className?: string;
    itemCardOption: ItemCardInfo;
    itemIds: number[];
    lazyLoadImage?: boolean;
    page?: number;
    pageSize?: number;
    trackClick?: (itemId: number, index: number) => void;
    trackVisible?: (itemId: number, isPromoted: boolean) => void;
};

export default function FluidItemGrid({
    breakpoints = DEFAULT_BREAKPOINTS,
    className,
    itemCardOption,
    itemIds,
    lazyLoadImage,
    page,
    pageSize,
    trackClick,
    trackVisible,
}: Props) {
    const { ItemCardComponent } = itemCardOption;

    const getPagePosition = (itemId) => {
        if (Boolean(page) && Boolean(pageSize)) {
            return (page - 1) * pageSize + (itemIds.indexOf(itemId) + 1);
        } else {
            return 0;
        }
    };

    return (
        <ItemGridContainer
            breakpoints={breakpoints}
            className={className}
            data-testid="ItemGridContainer"
        >
            {itemIds.map((itemId, index) => (
                <ItemCardComponent
                    itemId={itemId}
                    key={`${itemId}-${index}`}
                    lazyLoadImage={!lazyLoadImage && index <= 3 ? false : true}
                    pagePosition={getPagePosition(itemId)}
                    trackClick={trackClick}
                    trackVisible={trackVisible}
                />
            ))}
        </ItemGridContainer>
    );
}

const DEFAULT_ROW_GUTTER = theme.spacing.lgValue;
const DEFAULT_COLUMN_GUTTER = theme.spacing.mdValue;
const MOBILE_GUTTER = theme.spacing.smValue;

export const CUSTOM_MOBILE_BREAKPOINT = 375;

const DEFAULT_BREAKPOINTS = {
    breakpoints: {
        [theme.breakpoints.smMinValue]: {
            column: 4,
            gap: { column: DEFAULT_COLUMN_GUTTER, row: DEFAULT_ROW_GUTTER },
        },
        [CUSTOM_MOBILE_BREAKPOINT]: {
            column: 2,
        },
    },
    column: 1,
    gap: { column: MOBILE_GUTTER, row: MOBILE_GUTTER },
};

type ItemGridContainerProps = {
    breakpoints: GridBreakpoints;
};
const ItemGridContainer = styled.div<ItemGridContainerProps>`
    display: grid;
    justify-content: center;
    width: 100%;

    /**
     * The following styles are for the grid
     * the default grid looks like:
     *  
     * grid-template-columns: repeat(1, 1fr);
     * grid-row-gap: 16px;
     * grid-column-gap: 16px;
     * @media (min-width: 375px) {
     *   grid-template-columns: repeat(2, 1fr);
     * }
     * @media (min-width: 768px) {
     *   grid-template-columns: repeat(4, 1fr);
     *   grid-row-gap: 32px;
     *   grid-column-gap: 24px;
     * }
     */

    // for all breakpoints
    ${({ breakpoints }) => {
        // required base column
        let css = `grid-template-columns: repeat(${breakpoints.column}, 1fr);\n`;
        // optional grid gaps
        if (breakpoints?.gap) {
            if (breakpoints.gap?.column) {
                css += `grid-row-gap: ${breakpoints.gap.row}px;\n`;
            }
            if (breakpoints.gap?.row) {
                css += `grid-column-gap: ${breakpoints.gap.column}px;\n`;
            }
        }

        // for each optional break point
        for (const [breakpoint, values] of Object.entries(breakpoints.breakpoints)) {
            // create a media query
            css += `@media (min-width: ${breakpoint}px) {\n`;
            // required column for media query
            css += `    grid-template-columns: repeat(${values.column}, 1fr);\n`;
            // optional grid gaps
            if (values?.gap) {
                if (values.gap?.column) {
                    css += `    grid-row-gap: ${values.gap.row}px;\n`;
                }
                if (values.gap?.row) {
                    css += `    grid-column-gap: ${values.gap.column}px;\n`;
                }
            }
            css += '}\n';
        }

        return css;
    }}
`;
